/* Importación de fuentes */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=Playfair+Display:wght@400;700&family=Roboto+Slab:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

/* Variables globales */
:root {
    /* Colores */
    --gold-light: #f0e68c;
    --gold-dark: #daa520;
    --red-dark: #8b0000;
    --blue-deep: #1e3f5a;
    --blue-light: #c6efff;
    --green-dark: #2c5e2e;
    --white: #ffffff;
    --black: #1c1c1c;
    --cream: #f5f5dc;

    /* Fuentes */
    --font-primary: 'Roboto', sans-serif;
    --font-secondary: 'Cormorant Garamond', serif;
    --font-tertiary: 'Playfair Display', serif;

    /* Tamaños de fuente */
    --font-size-base: 1rem;
    --font-size-small: 0.875rem;
    --font-size-large: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 2rem;
    --font-size-schedule-title: 1.8rem;

    /* Espaciado */
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 1.5rem;

    /* Bordes y transiciones */
    --border-radius: 4px;
    --border-width: 1px;
    --transition-speed: 0.3s;

    /* Tamaños de tabla */
    --table-header-font-size: 1.8vw;
    --table-cell-font-size: 1.5vw;
    --table-padding: 0.3vw;
    --table-font-size-multiplier: 1;
}

/* Estilos generales */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--font-primary);
    line-height: 1.6;
    color: var(--black);
    background: linear-gradient(45deg, var(--gold-light), var(--gold-dark));
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-x: hidden;
}

#root, .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
    overflow-y: auto;
}

/* Estructura principal */
.container {
    flex-grow: 1;
    background: linear-gradient(270deg, rgb(251, 250, 241), rgb(252, 255, 234));
    border-radius: var(--border-radius);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    padding: var(--spacing-large);
    margin: var(--spacing-large) auto;
    max-width: 90%;
    width: 800px;
}

/* Estilos de encabezado */
.main-header {
    background: linear-gradient(to right, var(--gold-dark), var(--gold-light));
    padding: 10px;
    text-align: center;
    border-bottom: var(--border-width) solid var(--gold-dark);
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.main-title, .parish-name {
    font-size: var(--font-size-xxl);
    margin-bottom: var(--spacing-small);
}

.main-title {
    color: var(--red-dark);
}

.parish-name {
    color: var(--blue-deep);
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8);
}

.diocese-info {
    color: var(--green-dark);
    font-size: 0.9rem;
    font-weight: bolder;
    margin: 0;
    padding: 0 0.5em;
}

/* Estilos de navegación */
.main-navigation {
    background: var(--blue-deep);
    padding: var(--spacing-medium) 0;
}

.main-navigation ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.main-navigation li {
    margin: 0 var(--spacing-medium);
}

.main-navigation a {
    color: var(--white);
    text-decoration: none;
    font-weight: 500;
    transition: color var(--transition-speed) ease;
}

.main-navigation a:hover {
    color: var(--gold-light);
}

/* Estilos para títulos y textos */
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-family: var(--font-tertiary);
}

p {
    margin-bottom: 1em;
}

.schedule-title {
    color: var(--red-dark);
    font-size: var(--font-size-schedule-title);
    text-align: center;
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-medium);
}

/* Estilos de tabla */
table.schedule-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

table.schedule-table th,
table.schedule-table td {
    border: 1px solid var(--gold-dark);
    text-align: center;
    padding: var(--table-padding);
    vertical-align: middle;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: none;
}

table.schedule-table th {
    font-family: var(--font-tertiary);
    background-color: var(--red-dark);
    color: var(--white);
    font-weight: bold;
    font-size: calc(var(--table-header-font-size) * var(--table-font-size-multiplier));
    padding: calc(var(--table-padding) * 1.5);
}

table.schedule-table td {
    font-family: var(--font-primary);
    font-size: calc(var(--table-cell-font-size) * var(--table-font-size-multiplier));
    background-color: var(--white);
    color: var(--black);
}

table.schedule-table tr:nth-child(odd) td {
    background-color: rgba(240, 230, 140, 0.2);
}

/* Estilos para el mapa */
.map-container {
    margin-top: var(--spacing-large);
}

.map-title {
    font-size: clamp(var(--font-size-large), 4vw, var(--font-size-xxl));
    color: var(--red-dark);
    text-align: center;
    margin-bottom: var(--spacing-medium);
    font-family: var(--font-secondary);
}

.location-note {
    font-style: italic;
    color: var(--green-dark);
    margin-top: calc(-1.5 * var(--spacing-medium));
    text-align: justify;
    font-size: clamp(calc(0.8 * var(--font-size-base)), 2vw, var(--font-size-base));
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

/* Estilos para listas y líneas de tiempo */
.styled-list-container, .timeline {
    max-width: 800px;
    margin: 0 auto;
}

.styled-list, .timeline-item {
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}

.timeline-item {
    margin-bottom: 0px;
}

/* Media queries */
@media (max-width: 1024px) {
    :root {
        --table-font-size-multiplier: 1.2;
    }
    
    table.schedule-table td,
    table.schedule-table th {
        white-space: normal;
        word-break: break-word;
        hyphens: auto;
    }
}

@media (max-width: 768px) {
    :root {
        --table-font-size-multiplier: 1.5;
    }

    .main-header {
        padding: 15px 5px;
    }

    .parish-name {
        font-size: 1.5rem;
    }

    .diocese-info {
        font-size: 0.8rem;
    }

    .container {
        margin-top: 0.5rem;
        padding: 1rem;
    }

    .styled-list, .timeline-item {
        padding-left: 5vw;
    }
}

@media (max-width: 700px) {
    .timeline-item {
        padding: 0px 0;
    }

    .timeline-item:first-child {
        padding-top: 0;
    }

    .timeline-item:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .timeline-date,
    .timeline-content {
        padding-left: 30px;
        padding-right: 15px;
    }
}

@media (max-width: 480px) {
    :root {
        --table-font-size-multiplier: 1.8;
    }

    .parish-name {
        font-size: 1.2rem;
    }

    .diocese-info {
        font-size: 0.7rem;
    }

    .page-title {
        font-size: 1.5em;
    }

    .map-title {
        font-size: clamp(var(--font-size-large), 5vw, var(--font-size-xl));
    }
    
    .location-note {
        font-size: clamp(calc(0.7 * var(--font-size-base)), 2.5vw, calc(0.9 * var(--font-size-base)));
    }
}

@media (max-width: 320px) {
    .timeline-item {
        padding: 5px 0;
    }
}

@media (min-width: 540px) and (max-width: 750px) {
    .map-title {
        font-size: clamp(var(--font-size-xl), 5vw, calc(1.1 * var(--font-size-xxl)));
    }
}

@media (min-width: 1024px) {
    .styled-list, .timeline-item {
        padding-left: 60px;
    }
}

@media (min-width: 1200px) {
    .styled-list, .timeline-item {
        padding-left: 80px;
    }
}


