@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

:root {
  --gold-light: #f0e68c;
  --gold-dark: #daa520;
  --red-dark: #8b0000;
  --blue-deep: #1e3f5a;
  --green-dark: #2c5e2e;
  --white: #ffffff;
  --black: #1c1c1c;
}

body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

.nav-button {
  position: fixed !important;
  top: 0px !important;
  background-color: var(--red-dark) !important;
  color: var(--white) !important;
  padding: 0px 0px !important;
  min-width: 64px !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  border-radius: 4px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  transition: all 0.3s ease !important;
}

.nav-button:hover {
  background-color: var(--blue-deep) !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3) !important;
}

.menu-button,
.language-select {
  width: 100px !important; /* Ancho fijo para ambos elementos */
  margin-bottom: 5px !important;
}

.menu-button {
  background-color: var(--red-dark) !important;
  color: var(--white) !important;
  font-size: 0.8rem !important;
  padding: 5px 10px !important;
  text-transform: none !important;
  border: none !important;
  border-radius: 4px !important;
}

.language-select button {
  background-color: var(--red-dark) !important;
  color: var(--white) !important;
  font-size: 0.8rem !important;
  padding: 5px 10px !important;
  text-transform: none !important;
  border: none !important;
  border-radius: 4px !important;
  width: 100% !important; /* Asegura que el botón ocupe todo el ancho del contenedor */
}

.menu-button:hover,
.language-select button:hover {
  background-color: var(--blue-deep) !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3) !important;
}

.flag-button {
  background-color: var(--red-dark) !important;
  color: var(--white) !important;
  font-size: 0.8rem !important;
  padding: 5px 0px !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.flag-button:hover {
  background-color: var(--blue-deep) !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3) !important;
}

.flag-button img {
  width: 20px !important;
  height: 15px !important;
  margin-right: 8px !important;
}

.menu-list {
  width: 250px;
  background-color: var(--white);
}

.menu-list .MuiListItem-root {
  color: var(--blue-deep);
  font-family: 'Roboto', sans-serif;
}

.menu-list .MuiListItem-root:hover {
  background-color: var(--gold-light);
}

.navigation-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  background: linear-gradient(to right, var(--gold-dark), var(--gold-light));
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px; /* Ajusta según sea necesario */
}

.nav-icon {
  color: var(--red-dark) !important;
}

.language-container {
  position: relative;
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--gold-dark);
  z-index: 1101;
}

.language-menu button {
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  color: var(--blue-deep);
  display: block;
  width: 100%;
  text-align: left;
}

.drawer-paper {
  background: linear-gradient(to bottom, var(--gold-light), #e6d9b8);
  width: 250px;
  display: flex;
  flex-direction: column;
}

.drawer-header {
  padding: 20px;
  background-color: var(--red-dark);
  color: var(--white);
}

.drawer-header h2 {
  margin: 0;
  font-family: 'Playfair Display', serif;
}

.menu-list {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 15px 20px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important; /* Borde sutil */
  transition: background-color 0.3s ease !important;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important; /* Efecto hover sutil */
}

.menu-item .MuiListItemText-primary {
  color: var(--blue-deep);
  font-family: 'Playfair Display', serif;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .navigation-container {
    padding: 0px 5px;
  }
  
  .nav-icon {
    padding: 4px !important;
  }
}

@media (max-width: 600px) {
  th, td {
    font-size: 10px; /* Ajusta el tamaño de fuente para pantallas pequeñas */
    word-wrap: break-word; /* Permite el ajuste de palabras */
    white-space: normal; /* Permite el ajuste de líneas */
    overflow-wrap: break-word; /* Asegura que las palabras largas se ajusten */
  }
}

.MuiDrawer-root .MuiDrawer-paper.drawer-paper {
  background: #800020 !important; /* Color granate sólido */
  width: 250px !important;
  display: flex !important;
  flex-direction: column !important;
}

.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiList-root {
  background: transparent !important;
}

.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItem-root {
  background: transparent !important;
}

.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemText-root,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemText-primary,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiTypography-root {
  color: #ffffff !important; /* Texto blanco para todos los elementos */
  font-family: 'Playfair Display', serif !important;
  font-size: 1.1rem !important;
  font-weight: 700 !important; /* Aumentamos el peso de la fuente */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) !important; /* Añadimos sombra al texto */
}

/* Estilo para los íconos si los hay */
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemIcon-root {
  color: #ffd700 !important; /* Color dorado para los íconos */
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5)) !important; /* Sombra para los íconos */
}

/* Estilo para las flechas de expansión */
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root .MuiSvgIcon-root,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root .MuiSvgIcon-fontSizeMedium.css-20bmp1-MuiSvgIcon-root,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root .MuiSvgIcon-fontSizeMedium,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root svg {
  color: #ffffff !important;
  fill: #ffffff !important;
  opacity: 1 !important;
  font-size: 1.2rem !important;
  transition: transform 0.3s ease !important;
}

/* Rotación de la flecha cuando el ítem está expandido */
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root.Mui-expanded .MuiSvgIcon-root,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root.Mui-expanded svg {
  transform: rotate(180deg) !important;
}

/* Efecto hover para las flechas */
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root:hover .MuiSvgIcon-root,
.MuiDrawer-root .MuiDrawer-paper.drawer-paper .MuiListItemButton-root:hover svg {
  opacity: 0.8 !important;
}
