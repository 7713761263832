:root {
  --gold-light: #f0e68c;
  --gold-dark: #daa520;
  --red-dark: #8b0000;
  --blue-deep: #1e3f5a;
  --white: #ffffff;
  --black: #1c1c1c;
  --cream: #f5f5dc;
}

body {
  margin: 0;
  padding: 0;
  background: var(--cream);
  min-height: 100vh;
  font-family: 'Playfair Display', serif;
}

#root, .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  overflow-y: auto;
}

.main-header, .header-content {
  background: linear-gradient(to right, var(--gold-dark), var(--gold-light));
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
  text-align: center;
}

